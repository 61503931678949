import React from 'react';
import styles from './AddressModal.styles';
import { css } from 'aphrodite';
import ModalWrapper from '../Modal/Modal';
import STYLES from '../../../styles/shared';
import { EDIT_DROPOFF_ADDRESS_MODAL, EDIT_PICKUP_ADDRESS_MODAL, EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL, EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL, EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL, EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL } from '../../../library/modals';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../components/buttons/SecondaryButton/SecondaryButton';

const AddressModal = (props) => (
  <ModalWrapper {...props} width={800}>

    <div className={css(styles.headerArea)}>
      {props.currentModal === EDIT_PICKUP_ADDRESS_MODAL && <h3 className={css(styles.header)}>Pickup address</h3>}
      {props.currentModal === EDIT_DROPOFF_ADDRESS_MODAL && <h3 className={css(styles.header)}>Dropoff address</h3>}
      {props.currentModal === EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL && <h3 className={css(styles.header)}>Additional Stop 1</h3>}
      {props.currentModal === EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL && <h3 className={css(styles.header)}>Additional Stop 2</h3>}
      {props.currentModal === EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL && <h3 className={css(styles.header)}>Additional Stop 3</h3>}
      {props.currentModal === EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL && <h3 className={css(styles.header)}>Additional Stop 4</h3>}
    </div>

    <div className={css(STYLES.fullRow)}>
      <input value={props.line_1} placeholder={'Address line 1'} className={css(styles.input, styles.firstInput)}
             onChange={props.handleLine1Change}/>
    </div>
    <div className={css(STYLES.fullRow)}>
      <input value={props.line_2} placeholder={'Address line 2 (optional)'} className={css(styles.input)}
             onChange={props.handleLine2Change}/>
    </div>
    <div className={css(STYLES.fullRow)}>
      <input value={props.line_3} placeholder={'Address line 3 (optional)'} className={css(styles.input)}
             onChange={props.handleLine3Change}/>
    </div>
    <div className={css(STYLES.fullRow)} style={{flexWrap: 'wrap'}}>
      <input value={props.post_town} placeholder={'Town'} className={css(styles.input, styles.townInput)}
             onChange={props.handlePostTownChange}/>
      <input value={props.postcode} placeholder={'Postcode'} className={css(styles.input)}
             onChange={props.handlePostcodeChange}/>
    </div>

    <div className={css(styles.saveArea)}>
      {(!!props.bookingAddresses.pickup.postcodeError && props.currentModal === EDIT_PICKUP_ADDRESS_MODAL) && <p className={css(styles.errorMessage)}>
        {props.bookingAddresses.pickup.postcodeError}
      </p>}
      {(!!props.bookingAddresses.dropoff.postcodeError && props.currentModal === EDIT_DROPOFF_ADDRESS_MODAL) && <p className={css(styles.errorMessage)}>
        {props.bookingAddresses.dropoff.postcodeError}
      </p>}
      {(!!props.bookingAdditionalStops.postcodeError && props.currentModal !== EDIT_DROPOFF_ADDRESS_MODAL && props.currentModal !== EDIT_PICKUP_ADDRESS_MODAL) && <p className={css(styles.errorMessage)}>
        {props.bookingAdditionalStops.postcodeError}
      </p>}

      <div style={{flex: 1}}/>

      <SecondaryButton title={'Cancel'} onClick={props.cancel} />
      <PrimaryButton className={css(styles.saveBtnModal)} title={'Save'} style={{marginLeft: 12}} onClick={props.saveAddress} />
    </div>

  </ModalWrapper>
);

export default AddressModal;
