import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as modals from '../../../library/modals';
import { closeModal } from './ModalActions';
import EditAddressModal from '../Address/AddressModalContainer';
import LoginModalContainer from '../Login/LoginModalContainer';
import VerifyMobileModalContainer from '../VerifyMobile/VerifyMobileModalContainer';
import CreatePasswordModalContainer from '../CreatePassword/CreatePasswordContainer';
import ReserveModalContainer from '../ReserveBooking/ReserveBookingModalContainer';
import { navigate } from 'gatsby';

const ModalSwitcher = (props) => {
  switch (props.currentModal) {
    case modals.EDIT_PICKUP_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.EDIT_DROPOFF_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL:
      return <EditAddressModal {...props}/>;
    case modals.LOGIN_MODAL:
      return <LoginModalContainer {...props}/>;
    case modals.VERIFY_MOBILE_MODAL:
      return <VerifyMobileModalContainer {...props}/>;
    case modals.CREATE_PASSWORD_MODAL:
      return <CreatePasswordModalContainer {...props} />;
    case modals.RESERVE_BOOKING_MODAL:
      return <ReserveModalContainer {...props} />;
    default:
      return null;
  }
};

class ModalContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location && (this.props.location !== prevProps.location) && (this.props.currentModal !== null)) { // Close modal before allowing back button to navigate back
      navigate(prevProps.location.pathname);
      this.props.closeModal();
    }
  }

  render() {
    return <ModalSwitcher {...this.props} />
  }
}

function mapStateToProps(state) {
  return {
    currentModal: state.currentModal
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
