import { StyleSheet } from "aphrodite";
import COLORS from "../../../styles/colors";
import { FONTS } from "../../../styles/fonts";
import QUERIES from "../../../styles/queries";

const styles = StyleSheet.create({
  root: {
    // width: "100%",
    // paddingBottom: 0,
    // background: "linear-gradient(to bottom, #ffffff 1%, #f7f7f7)",
    // paddingTop: 48,
    // overflow: "hidden",
  },
  rootsub: {
    width: "100%",
    paddingBottom: 0,
    background: "#f8f8f8",
    paddingTop: 25,
    paddingBottom: 35,
  },
  contentsub: {
    maxWidth: 1200,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 20,
  },
  content: {
    maxWidth: 1600,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    overflow: "-moz-scrollbars-none",
    "-ms-overflow-style": "none",
    "::-webkit-scrollbar": {
      width: 0,
    },
  },
  reviewContainer: {
    flex: "0 0 20%",
    padding: 32,
    minWidth: 300,
  },
  reviewerBox: {
    textAlign: "center",
    color: COLORS.white,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  reviewerNameContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
  },
  reviewerName: {
    color: COLORS.jjdGreyish,
    margin: 0,
    paddingLeft: 2,
    paddingBottom: 2,
    textAlign: "left",
    fontSize: 22,
    fontWeight: "normal",
    [QUERIES.desktop]: {
      textAlign: "center",
    },
  },
  reviewHeading: {
    color: COLORS.jjdGreyish,
    textAlign: "center",
    fontSize: 48,
    fontWeight: 600,
    margin: 0,
  },
  reviewStarsContainer: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    marginTop: 20,
  },
  starIcon: {
    background: "#54b77a",
    color: COLORS.white,
    marginLeft: 4,
  },
  starIconsub: {
    color: "#54b77a",
    fontSize: 14,
  },
  quoteMarksContainer: {
    background: COLORS.white,
    width: 32,
    height: 32,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: -8,
    bottom: 0,
  },
  quoteMarks: {
    color: COLORS.kgDarkGreen,
  },
  reviewerImageContainer: {
    position: "relative",
  },
  reviewerImg: {
    width: 64,
    height: 64,
    borderRadius: "100%",
    overflow: "hidden",
  },
  reviewText: {
    color: COLORS.jjdGreyish,
    fontSize: 15,
    padding: 16,
    textAlign: "center",
    fontFamily: FONTS.Content,
    fontWeight: "normal",
  },
  excellcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [QUERIES.mobile]: {
      margin: "0 auto",
    },
  },
  exceltext: {
    fontWeight: 600,
    fontSize: 25,
    color: COLORS.jjdGreyish,
    margin: "0 20px 0 0",
    [QUERIES.mobile]: {
      fontSize: 35,
    },
  },
  excelstar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default styles;
