import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    position: 'fixed',
    top: 0,
    right: '100vw',
    width: '100vw',
    background: COLORS.white,
    height: '100vh',
    overflowY: 'scroll',
    zIndex: 100,
    transition: '340ms'
  },
  rootOpen: {
    right: 0,
    transition: '400ms'
  },
  closeContainer: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 28
  },
  downloadImage: {
    flex: 1,
    height: 54
  },
  closeIconContainer: {
    height: '100%',
    width: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeIcon: {
    fontSize: 32,
    marginRight: 8
  },
  menuItem: {
    // background: COLORS.kgDarkGreen,
    marginBottom: 12,
    borderBottom: '2px solid whitesmoke',
    marginRight: 24,
    marginLeft: 24,
    padding: 12,
    paddingLeft: 16,
    borderRadius: 4,
    color: COLORS.offBlack,
    fontWeight: '700',
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
  },
  getInTouchContainer: {
    textAlign: 'center',
    padding: 16,
    marginTop: 16,
    marginBottom: 112,
  },
  getInTouch: {
    fontSize: 18,
    marginBottom: 8
  },
  phoneNumber: {
    color: COLORS.jjdRed,
    fontWeight: '700',
    fontSize: 20,
    textDecoration: 'none'
  },


  // Services:
  otherServicesBox: {
    flex: 1,
    padding: 32,
    paddingTop: 64,
    [QUERIES.mobile]: {
      padding: 0
    },
    [QUERIES.desktop]: {
      display: 'flex',
      paddingRight: 64,
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  serviceLink: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid whitesmoke',
    textDecoration: 'none',
    padding: 16,
    color: COLORS.offBlack
  },
  serviceLinkActive: {
    background: COLORS.paper
  },
  serviceName: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  serviceIconContainer: {
    width: 32,
    height: 32,
    paddingRight: 32,
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default styles;
