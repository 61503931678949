import { StyleSheet } from "aphrodite";
import COLORS from "../../styles/colors";
import { FONTS } from "../../styles/fonts";
import QUERIES from "../../styles/queries";

export default StyleSheet.create({
  contactContainerMobile: {
    margin: "20px 0 30px",
    position: "fixed",
    bottom: 40,
    right: 27,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    padding: "10px",
    borderRadius: "10px",
    [QUERIES.mobile]: {
      display: "none",
    },
  },
  contactText: {
    fontSize: 16,
    margin: "0px",
    color: COLORS.jjdGreyish,
    fontWeight: 500,
  },
  contactNum: {
    fontSize: 24,
    margin: "0",
    color: COLORS.jjdGreyish,
    fontWeight: 700,
    textDecoration: "none",
  },
});
