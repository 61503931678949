import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import connect from 'react-redux/es/connect/connect';
import StripeMakePayment from './StripeMakePayment';
import { payReservation } from './StripeMakePaymentActions';
import ReactGA from 'react-ga';

class StripeMakePaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingForToken: false
    };
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    if (!this.state.waitingForToken) {

      this.setState({
        waitingForToken: true
      });

      try {
        var resp = await this.props.stripe.createToken({name: this.props.userDetails.firstName + ' ' + this.props.userDetails.lastName});
      } catch (err) {

        ReactGA.event({
          category: 'Reservation',
          action: 'Stripe - failed to create token',
          label: err
        });

        return;
      }

      if (resp.token) {
        this.props.receiveToken(resp.token);
        this.setState({
          waitingForToken: false
        });
      } else {
        this.setState({
          waitingForToken: false
        });
      }
    }
  }

  render() {
    return (
      <StripeMakePayment {...this.props} submit={this.submit} waitingForToken={this.state.waitingForToken} />
    );
  }
}


function mapStateToProps(state) {
  return {
    userDetails: state.userDetails,
    reservation: state.reservation
  }
}

function mapDispatchToProps(dispatch) {
  return {
    receiveToken: (token) => dispatch(payReservation(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(StripeMakePaymentContainer));
