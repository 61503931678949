import React from 'react';
import styles from './VerifyMobileModal.styles';
import { css } from 'aphrodite';
import ModalWrapper from '../Modal/Modal';
import STYLES from '../../../styles/shared';
import SentIcon from '@material-ui/icons/Sms';
import CircularProgress from '@material-ui/core/CircularProgress';

const VerifyMobileModal = (props) => (
  <ModalWrapper {...props} width={400}>

    <div className={css(styles.headerArea)}>
      <h3 className={css(styles.header)}>Enter verification code</h3>
      <p className={css(styles.helperText)}>
        <SentIcon className={css(styles.helperTextArrow)} /> 4-digit code sent to {props.userDetails.mobileNumber}
      </p>
    </div>

    <div className={css(STYLES.fullRow)} style={{position: 'relative'}}>
      <input value={props.code} placeholder={'- - - -'} className={css(styles.input, styles.firstInput)}
             onChange={props.onCodeChange}
             disabled={props.auth.checkingVerificationCode}
      />
      {(props.auth.checkingVerificationCode || props.isTyping) && <CircularProgress className={css(styles.checkingSpinner)} />}
    </div>

    {props.showError && <p className={css(styles.errorMessage)}>
      {props.auth.checkVerificationCodeError}
    </p>}

  </ModalWrapper>
);

export default VerifyMobileModal;
