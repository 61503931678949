import React from 'react';
import styles from './LoginModal.styles';
import { css } from 'aphrodite';
import ModalWrapper from '../Modal/Modal';
import STYLES from '../../../styles/shared';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../components/buttons/SecondaryButton/SecondaryButton';

const LoginModal = (props) => (
  <ModalWrapper {...props} width={600}>
    <div className={css(styles.headerArea)}>
      <h3 className={css(styles.header)}>{props.phoneLoginMethod? 'Enter mobile number' : 'Login'}</h3>
    </div>

    {/*Email login*/}
    {!props.phoneLoginMethod && <div>
      <div className={css(STYLES.fullRow)}>
        <input value={props.email} type={'email'} placeholder={'Email'} className={css(styles.input, styles.firstInput)}
               onChange={props.onEmailChange} autoCorrect={'off'}/>
      </div>
      <div className={css(STYLES.fullRow)}>
        <input value={props.password} type={'password'} placeholder={'Password'} className={css(styles.input)}
               onChange={props.onPasswordChange} autoCorrect={'off'} onKeyPress={props.onKeyPress}/>
      </div>
      {props.showError && <p className={css(styles.errorMessage)}>
        {props.auth.loginWithEmailAndPasswordError}
      </p>}
    </div>}

    {/*Phone login*/}
    {props.phoneLoginMethod && <div>
      <div className={css(STYLES.fullRow)}>
        <input value={props.mobileNumber} placeholder={'Mobile number'} className={css(styles.input, styles.firstInput)}
               onChange={props.onMobileNumberChange}
               onKeyPress={props.onKeyPress}
        />
      </div>
      {props.showError && <p className={css(styles.errorMessage)}>
        {props.auth.sendingVerificationCodeError}
      </p>}
    </div>}

    <div className={css(styles.actionArea)}>
      <div style={{flex: 1}}/>
      <SecondaryButton
        title={props.phoneLoginMethod? 'Email login' : 'Login with phone?'}
        onClick={props.switchLoginMethod}
        disabled={props.auth.sendingVerificationCode || props.auth.loggingInWithEmailAndPassword}
      />
      <div style={{width: 8}} />
      <PrimaryButton
        flat={true}
        onClick={props.attemptLogin}
        disabled={props.auth.sendingVerificationCode || props.auth.loggingInWithEmailAndPassword}
        title={props.phoneLoginMethod? 'Send verification code' : 'Login'}
      />
    </div>
  </ModalWrapper>
);

export default LoginModal;
