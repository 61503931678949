import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './SecondaryButton.styles';
import { css } from 'aphrodite';
import Button from '@material-ui/core/Button/Button';

const SecondaryButton = (props) => (
  <Button
    target={props.target || '_self'}
    style={{height: 48, ...props.style}}
    href={props.href || props.to}
    disabled={props.disabled || props.loading}
    variant='outlined'
    className={css(styles.root, props.color && styles[props.color], props.large && styles.large, props.flat && styles.flat)}
    onClick={props.onClick}>
    {props.loading && <CircularProgress color={'inherit'} size={16} thickness={6}/>}
    {!props.loading && props.title}
    {props.children}
  </Button>
);

export default SecondaryButton;
