import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { loginWithEmailAndPassword } from './LoginModalActions';
import LoginModal from './LoginModal';
import { sendVerificationCode } from '../../../actions/PersonalDetailsActions';
import { VERIFY_MOBILE_MODAL } from '../../../library/modals';
import { setCurrentModal } from '../Modal/ModalActions';

class LoginModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      email: '',
      password: '',
      phoneLoginMethod: false,
      showError: false
    };
  }

  componentDidMount() {
    this.setState({
      email: this.props.userDetails.email,
      mobileNumber: this.props.userDetails.mobileNumber,
      loginWithPhone: this.props.auth.hasAccount && !this.props.hasEmailAndPassword // Assume does not have an email and password combination
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.verificationCodeSent && !this.props.auth.verificationCodeSent) {
      this.props.setCurrentModal(VERIFY_MOBILE_MODAL)
    }

    // Login successful:
    if (nextProps.auth.isLoggedIn && !this.props.auth.isLoggedIn) {
      this.props.setCurrentModal(null)
    }

    if ((nextProps.auth.sendingVerificationCodeError && !this.props.sendingVerificationCodeError) || (nextProps.auth.loginWithEmailAndPasswordError && !this.props.loginWithEmailAndPasswordError)) {
      this.setState({
        showError: true
      })
    }
  }

  switchLoginMethod = () => {
    this.setState({
      phoneLoginMethod: !this.state.phoneLoginMethod,
      showError: false
    })
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      showError: false
    })
  };

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      showError: false
    })
  };

  onMobileNumberChange = (e) => {
    this.setState({
      mobileNumber: e.target.value,
      showError: false
    })
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.attemptLogin()
    }
  };

  attemptLogin = () => {
    if (this.state.phoneLoginMethod === true) {
      this.props.sendVerificationCode(this.state.mobileNumber);
    } else {
      this.props.login(this.state.email, this.state.password);
    }
  };

  render() {
    return (
      <LoginModal
        email={this.state.email}
        password={this.state.password}
        mobileNumber={this.state.mobileNumber}
        attemptLogin={this.attemptLogin}

        onEmailChange={this.onEmailChange}
        onPasswordChange={this.onPasswordChange}
        onMobileNumberChange={this.onMobileNumberChange}

        switchLoginMethod={this.switchLoginMethod}
        phoneLoginMethod={this.state.phoneLoginMethod}

        showError={this.state.showError}

        onKeyPress={this.onKeyPress}

        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userDetails: state.userDetails
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (e, p) => dispatch(loginWithEmailAndPassword(e, p)),
    sendVerificationCode: (mobileNumber) => dispatch(sendVerificationCode(mobileNumber)),
    setCurrentModal: (modal) => dispatch(setCurrentModal(modal))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalContainer);
