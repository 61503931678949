import { LINKS } from './links';

const REVIEWS = [
  {
    id: 1,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Mindaugas Ivoskus',
    title: 'Like always amazing service by JJD',
    body: 'Like always amazing service by JJD crew... Never late friendly guys recommend to everyone.',
    featured: false,
    reviewImage: require('../assets/avatars/1.png')
  },
  {
    id: 2,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Kristina',
    title: 'Thank you for a great service',
    body: 'Thank you for a great service it’s my second time to move from one house to another.Highly recommend to book a van with this company.',
    featured: false,
    reviewImage: require('../assets/avatars/11.png')
  },
  {
    id: 3,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Julio S',
    title: 'Good service',
    body: 'Good service, punctual and friendly driver.',
    featured: false,
    reviewImage: require('../assets/avatars/12.png')
  },
  {
    id: 4,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Customermik',
    title: 'Fantastic',
    body: 'Fantastic fantastic.',
    featured: false,
    reviewImage: require('../assets/avatars/13.png')
  },
  {
    id: 5,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Geri',
    title: 'Great service very efficient',
    body: 'Great service very efficient I would recommend it to my friends',
    featured: false,
    reviewImage: require('../assets/avatars/3.png')
  },
  {
    id: 6,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Steve E.',
    title: 'JJD provide an excellent service.',
    body: 'JJD provide an excellent service at a very reasonable price. The booking was easy with an immediate confirmation and the polite helpful driver turned up on time and took care with the movement of my sofas. I would definitely recommend to others.',
    featured: false,
    reviewImage: require('../assets/avatars/4.png')
  },
  {
    id: 7,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Angelina',
    title: 'It was my first time of choosing your company.',
    body: 'It was my first time of choosing your company. I had a really great experience. The gentlemen that was helping me was very kind and professional. I will definitely recommend your company. Thank you and all the best!',
    featured: false,
    reviewImage: require('../assets/avatars/5.png')
  },
  {
    id: 8,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Emma',
    title: 'Excellent service.',
    body: 'Excellent service.',
    featured: false,
    reviewImage: require('../assets/avatars/14.png')
  },
  {
    id: 9,
    stars: 3,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Customer',
    title: 'Good service James.',
    body: 'Good service James my driver was very nice and helpfully. Highly recommended!',
    featured: false,
    reviewImage: require('../assets/avatars/15.png')
  },
  {
    id: 10,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Martin Rollings',
    title: 'Great service on time and quick.',
    body: 'Great service on time and quick.',
    featured: true,
    reviewImage: require('../assets/avatars/13.png')
  },
  {
    id: 11,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Mr Dave Smith',
    title: 'JJD. Great service.',
    body: 'JJD did a great job. Carl was very good and efficient. He did exactly what was required and was very quick. Happy to recommend.',
    featured: true,
    reviewImage: require('../assets/avatars/9.png')
  },
  {
    id: 12,
    stars: 4,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Matt',
    title: 'The driver was on time.',
    body: 'The driver was on time, polite and friendly. Thanks for the great service!',
    featured: false,
    reviewImage: require('../assets/avatars/16.png')
  },
  {
    id: 13,
    stars: 3,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Customer',
    title: 'I was very satisfied by the service.',
    body: 'I was very satisfied by the service. Both mans where very efficient and very nice! Wonderful job!',
    featured: false,
    reviewImage: require('../assets/avatars/17.png')
  },
  {
    id: 14,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Customer',
    title: 'Brilliant service!',
    body: 'The driver was super nice and helpful. We enjoyed worked with you guys and will definitely call you guys again in the future! :)',
    featured: false,
    reviewImage: require('../assets/avatars/18.png')
  },
  {
    id: 15,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Paul Grice',
    title: 'Good service',
    body: 'Good service. Quick polite workers. All went smoothly thanks.',
    featured: false,
    reviewImage: require('../assets/avatars/8.png')
  },
  {
    id: 16,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Lou Anne A.',
    title: 'Happy Customer',
    body: 'Im so glad that after all the search ive made, I ended choosing JJD. It was my first move, & everything went smoothly. I would highly recommend ur services, it was amazing. Reasonable price, on time, and most of all Thank you again to Paul & Carl youve done an excellent job. Keep up the good work JJD i would definitely get ur services again if needed.',
    featured: false,
    reviewImage: require('../assets/avatars/9.png')
  },
  {
    id: 17,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Theo Lewis',
    title: 'Perfect experience',
    body: 'Perfect experience. Will be using again for my next move, thanks guys!',
    featured: true,
    reviewImage: require('../assets/avatars/10.png')
  },
  {
    id: 18,
    stars: 4,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Wayne',
    title: 'Punctual & professional.',
    body: 'Punctual & professional.',
    featured: false,
    reviewImage: require('../assets/avatars/1.png')
  },
  {
    id: 19,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Sharon Alubo',
    title: 'Timely service.',
    body: 'Timely, friendly, and agile service man. The van was in good condition and drove safely.',
    featured: false,
    reviewImage: require('../assets/avatars/19.png')
  },
  {
    id: 20,
    stars: 3,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Antonia Thorp',
    title: 'Great service',
    body: 'Great service - well priced and helpful driver.',
    featured: false,
    reviewImage: require('../assets/avatars/2.png')
  },
  {
    id: 21,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Happy customer',
    title: 'This is the first time we used JJD',
    body: 'This is the first time we used JJD for help in moving. They arrived on time spot on 9am as agreed and we got to the delivered addres in good time. They moved us from London to Birmingham and the price cannot be get any better. Very affordable and reliable. There customer service is good and they listen to you when booking and answer all questions and stick to what has been agreed upon. I am a very happy customer and will be using your service in the future.',
    featured: false,
    reviewImage: require('../assets/avatars/3.png')
  },
  {
    id: 22,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Amani',
    title: 'Excellent service very friendly staff.',
    body: 'Excellent service very friendly staff.',
    featured: false,
    reviewImage: require('../assets/avatars/20.png')
  },
  {
    id: 23,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Jennifer Nickson',
    title: 'Very good.',
    body: 'Very good. Well organised',
    featured: false,
    reviewImage: require('../assets/avatars/4.png')
  },
  {
    id: 24,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'Arun',
    title: 'Very nice people and attentive helpful.',
    body: 'very nice people and attentive helpful and finished their tasks promptly.',
    featured: true,
    reviewImage: require('../assets/avatars/18.png')
  },
  {
    id: 25,
    stars: 5,
    platform: 'Trustpilot',
    link: LINKS.Trustpilot,
    name: 'C Osborn',
    title: 'Ali turned up 10 mins early and was',
    body: 'Ali turned up 10 mins early and was ready to start straight away. We honestly wouldn’t have made it out of our old flat if it wasn’t for him. He was professional throughout and so helpful despite our stress and chaos. Really appreciate it and 100% recommend.',
    featured: true,
    reviewImage: require('../assets/avatars/6.png')
  }
];

export default REVIEWS;
