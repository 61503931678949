import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const headerHeight = 100;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: headerHeight,
    paddingRight: 24,
    paddingLeft: 24,
    background: COLORS.white,
    marginBottom: -1 // removes thin line appearing for some reason ...
  },
  rootOverlay: {
    position: 'absolute',
    top: 0,
    background: 'transparent',
    zIndex: 10
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row'
  },
  linksUl: {
    'user-select': 'none',
    flex: 1,
    margin: 0,
    display: 'flex',
    height: headerHeight,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '-webkit-box-pack': 'end',
    position: 'relative',
    paddingLeft: 0,
    zIndex: 999,
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  servicesDropdown: {
    width: 512,
    // height: 288,
    backgroundColor: '#731d18',
    color: 'white',
    position: 'absolute',
    left: 0,
    marginLeft: -256 + 50,
    top: 50,
    zIndex: 10,
    boxShadow: '0 16px 40px rgba(0,0,0,0.64)',
    borderRadius: 16,
    transition: '150ms',
    //padding: 16,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    // Invisible
    opacity: 0,
    pointerEvents: 'none'
  },
  servicesDropdownVisible: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  arrowUp: {
    width: 0,
    height: 0,
    position: 'absolute',
    top: -10,
    left: '50%',
    marginLeft: '-5px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #222433'
  },
  serviceOption: {
    flex: '0 0 50%',
    fontSize: 14,
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    padding: 24,
    opacity: 0.8,
    transition: '150ms',
    color: COLORS.white,
    textDecoration: 'none',
    ':hover': {
      // background: 'rgba(255,255,255,0.08)'
      opacity: 1
    }
  },
  serviceOptionFlash: {
    opacity: 1,
    transition: '200ms'
  },
  logoLinksUl: {
    'user-select': 'none',
    margin: 0,
    display: 'flex',
    height: headerHeight,
    alignItems: 'center',
    paddingLeft: 0,
    zIndex: 1,
    [QUERIES.mobile]: {
      order: 2,
    },
    [QUERIES.lessThan600]: {
      margin: '0 auto',
    }
  },
  linkLi: {
    'user-select': 'none',
    position: 'relative',
    ':hover': {
      cursor: 'pointer'
    }
  },
  linkLiButton: {
    marginLeft: 12,
    marginRight: 12,
    background: COLORS.jjdRed,
    borderRadius: '48px',
    borderColor: 'transparent',
    fontWeight: 'normal',
    ':hover': {
      cursor: 'pointer'
    }
  },
  link: {
    paddingLeft: 24,
    paddingRight: 24,
    // marginRight: 8,
    // marginLeft: 8,
    fontSize: 14,
    textDecoration: 'none',
    fontWeight: 'normal',
    height: headerHeight,
    paddingTop: headerHeight / 2 - 9,
    paddingBottom: headerHeight / 2 - 9,
    color: COLORS.jjdGreyish,
    ':hover': {
      cursor: 'pointer',
      color: COLORS.jjdRed
      // borderBottom: '2px solid rgba(0,0,0,0.16)',// + COLORS.kgDarkGreen
    }
  },
  linkOverlay: {
    color: COLORS.white,
    ':hover': {
      color: COLORS.white,
      opacity: 1
    }
  },
  welcomeBack: {
    color: COLORS.kgDarkGreen,
    ':hover': {
      cursor: 'default'
    }
  },
  headerLogo: {
    height: 26,
    draggable: false,
    'user-select': 'none'
  },
  headerIconLogo: {
    height: "100%",
    width: 120,
    marginRight: 12,
    [QUERIES.lessThan600]: {
      marginRight: "50%",
      transform: "translate(-50%,0)",
    }
  },
  menuIconContainer: {
    flex: 1,
    margin: 0,
    display: 'flex',
    height: headerHeight,
    maxWidth: 100,
    alignItems: 'center',
    justifyContent: 'flex-start',
    '-webkit-box-pack': 'end',
    position: 'relative',
    zIndex: 999,
    paddingLeft: 0,
    [QUERIES.desktop]: {
      display: 'none'
    }
  },
  menuIcon: {
    //color: COLORS.white
  }
});

export default styles;
