import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    // ...
  },
  content: {
    display: 'flex'
  },
  headerArea: {
    padding: 24,
  },
  header: {
    margin: 0
  },
  helperText: {
    fontSize: 16,
    paddingTop: 12,
    margin: 0
  },
  input: {
    flex: 1,
    padding: 24,
    fontSize: 22,
    display: 'flex',
    border: 'none',
    // borderBottom: '1px solid whitesmoke',
    color: COLORS.offBlack,
    width: '100%'
  },
  firstInput: {
    borderTop: '1px solid whitesmoke'
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24,
    paddingTop: 12
  },
  loginButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    // width: 80,
    height: 39
  },
  forgotPasswordButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    color: '#757575',
    marginRight: 16
  },
  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 14,
    paddingLeft: 24,
    paddingTop: 12,
    lineHeight: '39px',
    margin: 0
  },
  successContainer: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 32,
    paddingRight: 32
  },
  doneIcon: {
    color: COLORS.kgDarkGreen,
    height: 64,
    width: 64,
    [QUERIES.mobile]: {
      textAlign: 'center',
      paddingBottom: 24,
      margin: '0 auto'
    }
  },
  doneText: {
    color: COLORS.kgDarkGreen,
    fontSize: 14,
    paddingTop: 16,
    paddingLeft: 24,
    margin: 0,
    flex: 1,
    [QUERIES.mobile]: {
      padding: 0,
      flex: '0 0 100%'
    }
  }
});

export default styles;
