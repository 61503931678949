import React from 'react';
import styles from './CreatePassword.styles';
import { css } from 'aphrodite';
import ModalWrapper from '../Modal/Modal';
import STYLES from '../../../styles/shared';
import DoneIcon from '@material-ui/icons/Done';
import SecondaryButton from '../../../components/buttons/SecondaryButton/SecondaryButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';

const CreatePasswordModal = (props) => (
  <ModalWrapper {...props} width={600}>
    <div className={css(styles.headerArea)}>
      <h3 className={css(styles.header)}>Create account with Kiffgo?</h3>
      <p className={css(styles.helperText)}>We just need a password for {props.userDetails.email}</p>
    </div>

    {!props.auth.hasEmailAndPassword && <div className={STYLES.fullRow}>
      <input value={props.password} placeholder={'Password'} className={css(styles.input)}
             type="password"
             onKeyPress={props.onKeyPress}
             onChange={props.onPasswordChange}/>
    </div>}

    {props.showError && <p className={css(styles.errorMessage)}>
      {props.auth.setEmailPasswordError || 'Must be at least 6 characters'}
    </p>}

    {!props.auth.hasEmailAndPassword && <div className={css(styles.actionArea)}>
      <div style={{flex: 1}}/>

      <SecondaryButton
        title={'I will never need a van ever again'} onClick={props.cancel}
        disabled={props.auth.sendingVerificationCode || props.auth.loggingInWithEmailAndPassword}
      />
      <PrimaryButton
        disabled={props.auth.sendingVerificationCode || props.auth.loggingInWithEmailAndPassword}
        title={'Create password'} style={{marginLeft: 12}} onClick={props.submit} />
    </div>}

    {props.auth.hasEmailAndPassword && <div className={css(styles.successContainer)}>
      <DoneIcon className={css(styles.doneIcon)} />
      <div className={css(styles.doneText)}>Password created successfully! You'll be able to login easily next time you book with Kiffgo :)</div>
    </div>}

    {props.auth.hasEmailAndPassword && <div className={css(styles.actionArea)}>
      <div style={{flex: 1}}/>
      <PrimaryButton
        title={'Return home'} style={{marginLeft: 12}} onClick={props.done} />
    </div>}


  </ModalWrapper>
);

export default CreatePasswordModal;
