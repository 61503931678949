import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import AddressModal from './AddressModal';
import {
  EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL,
  EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL,
  EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL,
  EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL,
  EDIT_DROPOFF_ADDRESS_MODAL,
  EDIT_PICKUP_ADDRESS_MODAL
} from '../../../library/modals';
import { saveAddressManually, saveAdditionalStopAddressManually } from './AddressModalActions';
import { clearAddress } from '../../../actions/AddressActions';

class EditAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line_1: '',
      line_2: '',
      line_3: '',
      post_town: '',
      postcode: ''
    };
  }

  componentDidMount() {
    this.setState({
      line_1: this.props.address.line_1,
      line_2: this.props.address.line_2,
      line_3: this.props.address.line_3,
      post_town: this.props.address.post_town,
      postcode: this.props.address.postcode
    })
  }

  handleLine1Change = (e) => {
    this.setState({
      line_1: e.target.value,
    })
  };

  handleLine2Change = (e) => {
    this.setState({
      line_2: e.target.value
    })
  };

  handleLine3Change = (e) => {
    this.setState({
      line_3: e.target.value
    })
  };

  handlePostTownChange = (e) => {
    this.setState({
      post_town: e.target.value
    })
  };

  handlePostcodeChange = (e) => {
    this.setState({
      postcode: e.target.value
    })
  };

  saveAddress = () => {
    if (this.props.currentModal === EDIT_PICKUP_ADDRESS_MODAL || this.props.currentModal === EDIT_DROPOFF_ADDRESS_MODAL) {
      const whichAddress = this.props.currentModal === EDIT_PICKUP_ADDRESS_MODAL ? 'pickup' : 'dropoff';
      this.props.saveAddress(whichAddress, this.state.line_1, this.state.line_2, this.state.line_3, this.state.post_town, this.state.postcode);
      this.props.closeModal();
    } else {

      let stopIndex = 0;
      if (this.props.currentModal === EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL) stopIndex = 1;
      if (this.props.currentModal === EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL) stopIndex = 2;
      if (this.props.currentModal === EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL) stopIndex = 3;

      this.props.saveAdditionalStopAddress(stopIndex, this.state.line_1, this.state.line_2, this.state.line_3, this.state.post_town, this.state.postcode);
      this.props.closeModal();
    }
  };

  cancel = () => {
    if (this.props.bookingAddresses.pickup.postcodeError && this.props.currentModal === EDIT_PICKUP_ADDRESS_MODAL) {
      this.props.clearAddress('pickup');
    } else if (this.props.bookingAddresses.dropoff.postcodeError && this.props.currentModal === EDIT_DROPOFF_ADDRESS_MODAL) {
      this.props.clearAddress('dropoff');
    }

    // then close
    this.props.closeModal();
  };

  render() {
    return (
      <AddressModal
        {...this.props}
        saveAddress={this.saveAddress}
        line_1={this.state.line_1}
        handleLine1Change={this.handleLine1Change}

        line_2={this.state.line_2}
        handleLine2Change={this.handleLine2Change}

        line_3={this.state.line_3}
        handleLine3Change={this.handleLine3Change}

        post_town={this.state.post_town}
        handlePostTownChange={this.handlePostTownChange}

        postcode={this.state.postcode}
        handlePostcodeChange={this.handlePostcodeChange}

        cancel={this.cancel}
      />
    )
  }
}

function whichAddress(state) {
  if (state.currentModal === EDIT_PICKUP_ADDRESS_MODAL) {
    return state.bookingAddresses.pickup
  } else if (state.currentModal === EDIT_DROPOFF_ADDRESS_MODAL) {
    return state.bookingAddresses.dropoff
  } else if (state.currentModal === EDIT_ADDITIONAL_STOP_0_ADDRESS_MODAL) {
    return state.bookingAdditionalStops.stops[0]
  } else if (state.currentModal === EDIT_ADDITIONAL_STOP_1_ADDRESS_MODAL) {
    return state.bookingAdditionalStops.stops[1]
  } else if (state.currentModal === EDIT_ADDITIONAL_STOP_2_ADDRESS_MODAL) {
    return state.bookingAdditionalStops.stops[2]
  } else if (state.currentModal === EDIT_ADDITIONAL_STOP_3_ADDRESS_MODAL) {
    return state.bookingAdditionalStops.stops[3]
  }
}

function mapStateToProps(state) {
  return {
    bookingAddresses: state.bookingAddresses,
    bookingAdditionalStops: state.bookingAdditionalStops,
    address: whichAddress(state)
  }
}

const mapDispatchToProps = {
  saveAddress: saveAddressManually,
  saveAdditionalStopAddress: saveAdditionalStopAddressManually,
  clearAddress: clearAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressModal);
