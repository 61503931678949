import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    // ...
  },
  content: {
    display: 'flex'
  },
  headerArea: {
    padding: 24,
  },
  header: {
    margin: 0,
    fontFamily: FONTS.Content,
    color: '#b4b4b4'
  },
  input: {
    flex: 1,
    padding: 24,
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    color: COLORS.offBlack
  },
  firstInput: {
    borderTop: '1px solid whitesmoke'
  },
  townInput: {
    borderRight: '1px solid whitesmoke'
  },
  saveArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24
  },
  saveButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    width: 80,
    height: 39,
    marginLeft: 16
  },
  cancelButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    color: '#757575',
    marginRight: 16
  },
  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '39px',
    margin: 0
  },
  saveBtnModal: {
    fontWeight: 'bold',
    fontFamily: FONTS.Content,
    textTransform: 'none',
    minWidth: 100,
    height: 48,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 14,
    borderRadius: 48,
  }
});

export default styles;
