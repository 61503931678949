import React from 'react';
import styles from './ReserveBookingModal.styles';
import { css } from 'aphrodite';
import ModalWrapper from '../Modal/Modal';
import STYLES from '../../../styles/shared';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import { Elements, StripeProvider } from 'react-stripe-elements';
import DoneIcon from '@material-ui/icons/Done';
import StripeMakePaymentContainer from '../../reserve/StripeMakePayment/StripeMakePaymentContainer';
import { Link } from 'gatsby';
import { KIFFGO } from '../../../library/kiffgo';

var moment = require('moment');

const ReserveBookingModal = (props) => (
  <ModalWrapper {...props} width={600}>
    {props.reservation.reservationMade && <div className={css(styles.paymentContainer)}>
      <div className={css(styles.cardContainer)}>
        <div className={css(styles.cardContainerContent)}>
          <div className={css(styles.titleArea)}>
            <h1 className={css(styles.paymentMethodTitle)}>Confirm reservation</h1>
            <div style={{flex: 1}}/>
            <div className={css(styles.acceptedCardsContainer)}>
              {[1, 2, 10, 14, 16, 22].map(card => {
                return (
                  <img key={card} className={css(styles.cardImg)}
                       alt={'Book a man and van online'}
                       src={require('../../../assets/cards/' + card.toString() + '.png')}/>
                )
              })}
              <div className={css(styles.secureLabel)}>Payments taken securely through <a
                style={{textDecoration: 'underline'}} target={'_blank'} href={'https://stripe.com/gb'}>Stripe</a>
              </div>
            </div>
          </div>

          <p className={css(styles.confirmationText)}>
            Confirm your reservation now to guarantee a time slot. You can provide the full details of your move in the
            next step. General pricing information can be found <Link target={'_blank'} to={'/pricing'}>here</Link>
          </p>

          <p className={css(styles.confirmationTextTwo)}>
            Please note: drivers are to be paid separately
          </p>

          <div className={css(styles.priceBreakdownContainer)}>
            <div className={css(STYLES.fullRow, styles.cardRow)} style={{paddingTop: 32}}>
              {props.userDetails.hasCard && <div>Card</div>}
              <div style={{flex: 1}}/>
              {props.userDetails.hasCard && <div>*** {props.userDetails.card.last4}</div>}
            </div>
            <div className={css(STYLES.fullRow)}>
              <div>Reservation fee</div>
              <div style={{flex: 1}}/>
              <div>£20.00</div>
            </div>
          </div>

          {!props.reservation.paymentSuccessful && <StripeProvider apiKey={props.stripeApiKey}>
            <Elements>
              <StripeMakePaymentContainer/>
            </Elements>
          </StripeProvider>}

          {props.reservation.paymentSuccessful && <div className={css(styles.paymentSuccessContainer)}>
            <DoneIcon className={css(styles.doneIcon)}/>
            <div className={css(styles.doneText)}>
              Reservation confirmed! Call us on <a href={KIFFGO.phoneNumberHrefLink}>{KIFFGO.phoneNumber}</a> now to
              complete your booking details, or wait for our email with instructions.
            </div>
          </div>}

        </div>
      </div>

    </div>}
    {!props.reservation.reservationMade && <div>
      <div className={css(styles.headerArea)}>
        <h3 className={css(styles.header)}>Reserve your booking</h3>
        <p className={css(styles.description)}>Secure your slot
          for {moment(props.reservation.arrival).format('dddd, Do MMMM')}</p>
        <p className={css(styles.description)}>We will email you the price for this slot.</p>
      </div>
      <div>
        <div className={css(STYLES.fullRow)}>
          <input value={props.mobileNumber} placeholder={'Mobile number'} className={css(styles.input)}
                 onChange={props.onMobileNumberChange} autoCorrect={'off'}/>
        </div>
        <div className={css(STYLES.fullRow)}>
          <input value={props.email} placeholder={'Email'} className={css(styles.input)}
                 onChange={props.onEmailChange} autoCorrect={'off'}/>
        </div>
        {props.error && <p className={css(styles.errorMessage)}>
          {props.error}
        </p>}
      </div>

      <div className={css(styles.actionArea)}>
        <div style={{flex: 1}}/>
        <PrimaryButton onClick={props.continue} loading={props.loading}
                       disabled={props.auth.sendingVerificationCode || props.auth.loggingInWithEmailAndPassword}
                       title={'Continue'}/>
      </div>
    </div>}
  </ModalWrapper>
);

export default ReserveBookingModal;
