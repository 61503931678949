import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    padding: 0,
    background: COLORS.jjdMaroon,
    margin: 0,
    textAlign: 'center'
  },
  footerSectionsRoot: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 80,
    flexWrap: 'wrap',
  },
  header: {
    margin: 0,
    color: COLORS.footerText,
    fontSize: 15,
    fontWeight: 'normal',
    paddingBottom: 12,
  },
  content: {
    padding: 16,
    maxWidth: 1200,
    margin: '0 auto'
  },
  link: {
    color: COLORS.white,
    fontSize: 15,
    fontWeight: 'normal',
    textDecoration: 'none',
    ':hover': {
      color: COLORS.white,
      cursor: 'pointer',
    }
  },
  linkLegal: {
    color: COLORS.white,
    textDecoration: 'none',
    padding: 4,
    ':hover': {
      color: COLORS.white,
      cursor: 'pointer',
    },
    flex: 1,
    fontSize: 15,
    textAlign: 'center',
    [QUERIES.mobile]: {
      // marginBottom: 24,
      fontSize: 13,


    }
  },
  socialLink: {
    padding: '4px 8px'
  },
  socialLinkImg: {
    maxHeight: 22
  },
  footerLogo: {
    width: 180,
    paddingTop: 16
  },
  contactNumber: {
    color: COLORS.white,
  },
  legalLinks: {
    // flexWrap: 'wrap',
  },
  legalBlurb: {
    color: 'white',
    fontSize: 14,
    flex: 1,
    [QUERIES.mobile]: {
      flex: '0 0 100%',
      textAlign: 'center',
      lineHeight: '2em',
    },
  },
  addressBlurb: {
    maxWidth: 600,
    margin: '0 auto',
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
    paddingBottom: 64
  },
  footerSection: {
    width: '100%',
    paddingTop: 64,
    textAlign: 'left',
    // minWidth: 320,
    [QUERIES.desktop]: {
      // flex:'1 1 20%',
      width: 'auto',
      minWidth: 10,
      textAlign: 'left'
    },
  },
  footerSectionone: {
    [QUERIES.desktop]: {
      flex: '0 0 20%',
    }
  },
  footerSectiontwo: {
    [QUERIES.desktop]: {
      flex: '0 0 20%',
    }
  },
  footerSectionthree: {
    [QUERIES.desktop]: {
      flex: 1,
    }
  },
  ul: {
    padding: 0,
    margin: 0
  },
  li: {
    fontSize: 18,
    padding: '8px 0',
    margin: 0,
    fontWeight: 700
  },
  downloadIcon: {
    borderRadius: 12,
    margin: '4px 0 0 0',
    overflow: 'hidden',
    maxWidth: 240,
    //boxShadow: inset 0 0 10px black,
  },
  socialLi: {
    paddingTop: 32,
    [QUERIES.mobile]: {
      display: 'none',
    }
  },
  socialLimobile: {
    paddingTop: 32,
    [QUERIES.desktop]: {
      display: 'none',
    }
  },
  socialLimain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  serviceslink: {
    [QUERIES.desktop]: {
      width: '50%',
      float: 'left',
    }
  },
  copyrightFooter: {
    background: COLORS.black,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    padding: 27,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  [QUERIES.mobile]: {
    padding: 20,
  },
  footerIconLogo: {
    height: "100%",
    width: 120,
  }
});

export default styles;
