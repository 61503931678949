import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./PrimaryButton.styles";
import { css } from "aphrodite";
import Button from "@material-ui/core/Button/Button";

const PrimaryButton = (props) => (
  <Button
    style={{ height: 48, ...props.style }}
    href={props.href || props.to}
    disabled={props.disabled || props.loading}
    variant="contained"
    color={"primary"}
    className={css(
      styles.root,
      props.color && styles[props.color],
      props.large && styles.large,
      props.flat && styles.flat
    )}
    className={`${props.className}`}
    onClick={props.onClick}
  >
    {props.loading && (
      <CircularProgress color={"inherit"} size={16} thickness={6} />
    )}
    {!props.loading && props.title}
  </Button>
);

export default PrimaryButton;
