import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import VerifyMobileModal from './VerifyMobileModal';
import { checkVerificationCode, resetVerifyMobile } from './VerifyMobileModalActions';

class VerifyMobileModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      code: '',
      showError: false,
      isTyping: false
    };

    this.activeTimeout = null;
  }

  componentDidMount() {
    this.setState({
      mobileNumber: this.props.userDetails.mobileNumber
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.checkVerificationCodeError && !this.props.auth.checkVerificationCodeError) {
      this.setState({
        showError: true,
        isTyping: false
      })
    }

    if (nextProps.auth.isLoggedIn && !this.props.auth.isLoggedIn) {
      this.props.closeModal();
    }
  }

  onMobileNumberChange = (e) => {
    this.setState({
      mobileNumber: e.target.value,
    })
  };

  onCodeChange = (e) => {
    this.activeTimeout && clearTimeout(this.activeTimeout);
    this.setState({
      code: e.target.value,
      showError: false,
      isTyping: true
    }, () => {
      this.activeTimeout = setTimeout(() => {
        if (this.state.code.toString().length === 4) {
          this.props.checkVerificationCode(this.state.code)
        } else {
          this.setState({
            isTyping: false
          })
        }
      }, 500);
    })
  };

  render() {
    return (
      <VerifyMobileModal
        mobileNumber={this.state.mobileNumber}
        code={this.state.code}
        onMobileNumberChange={this.onMobileNumberChange}
        onCodeChange={this.onCodeChange}
        showError={this.state.showError}
        isTyping={this.state.isTyping}
        {...this.props}
      />
    )
  }

  componentWillUnmount() {
    this.props.resetVerifyMobile()
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userDetails: state.userDetails
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkVerificationCode: (code) => dispatch(checkVerificationCode(code)),
    resetVerifyMobile: () => dispatch(resetVerifyMobile())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobileModalContainer);
