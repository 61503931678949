export const FONTS = {
  Headers: 'Nunito',
  Buttons: 'Nunito',
  Content: 'Nunito'
};

// Thin
// Thin Italic
// Light
// Light Italic
// Regular
// Regular Italic
// Medium
// Medium Italic
// Bold
// Bold Italic
// Black
// Black Italic
