import COLORS from '../styles/colors';
import Weekend from '@material-ui/icons/Weekend';
import Home from '@material-ui/icons/Home';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Flat from '@material-ui/icons/LocationCity';
import Office from '@material-ui/icons/Laptop';
import Piano from '@material-ui/icons/VolumeDown';
import Store from '@material-ui/icons/Store';
// import Other from '@material-ui/icons/AccountBox';
import React from 'react';

export const CATEGORIES = [
  {
    index: 0,
    name: 'Lightweight',
    description: 'Luggage, boxes, items, all up to 30kg',
    color: COLORS.kgAquaBlue,
    img: require('../assets/services/iso-luggage-512.png'),
    minPrice: 4375
  },
  {
    index: 1,
    name: 'Move Large Item',
    description: 'Furniture, appliances, anything over 30kg',
    color: COLORS.kgGreen,
    img: require('../assets/services/iso-appliance-512.png'),
    minPrice: 5000
  },
  {
    index: 2,
    name: 'Moving Service',
    description: 'Relocate home or office, piano move',
    color: COLORS.kgMidGreen,
    img: require('../assets/services/iso-removal-512.png'),
    minPrice: 8125
  }
];


export const SERVICES = [
  {
    name: 'Furniture & General Items',
    jobName: 'Furniture & General Items',
    icon: (styleProps) => (
      <Weekend style={styleProps}/>
    ),
    img: require('../assets/services/furniture.jpeg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/furniture-courier'
  },
  {
    name: 'House Removals',
    jobName: 'House Removal',
    icon: (styleProps) => (
      <Home style={styleProps} />
    ),
    img: require('../assets/services/home.jpg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/house-removals'
  },
  {
    name: 'Moving Flat or Apartment',
    jobName: 'Flat Move',
    icon: (styleProps) => (
      <Flat style={styleProps}/>
    ),
    img: require('../assets/services/flat.jpg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/moving-flat'
  },
  {
    name: 'Office Moves',
    jobName: 'Office Move',
    icon: (styleProps) => (
      <Office style={styleProps}/>
    ),
    img: require('../assets/services/office.jpg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/office-moves'
  },
  {
    name: 'Piano or Electronic Equipment',
    jobName: 'Piano Move',
    icon: (styleProps) => (
      <Piano style={styleProps}/>
    ),
    img: require('../assets/services/piano.jpg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/piano-movers'
  },
  {
    name: 'Same-Day Delivery',
    jobName: 'General Delivery',
    icon: (styleProps) => (
      <LocalShipping style={styleProps} />
    ),
    img: require('../assets/services/home.jpg'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/same-day-delivery'
  },
  {
    name: 'Pickup from Store',
    jobName: 'Store Pickup',
    icon: (styleProps) => (
      <Store style={styleProps} />
    ),
    img: require('../assets/services/real-van.png'),
    color: 'rgba(0, 0, 0, 0.8)',
    url: '/services/pickup-from-store'
  }
];
