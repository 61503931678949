import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import ReserveBookingModal from "./ReserveBookingModal";
import Utils from "../../../library/utils";
import {
  makeReservation,
  resetReservation,
} from "./ReserveBookingModalActions";
import ReactGA from "react-ga";

class ReserveBookingModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobileNumber: "",
      email: "",
      error: null,
      currentStep: 1,
      loading: false,
    };

    this.stripeApiKey =
      `${process.env.GATSBY_API_URL}` === "https://api.jjdvans.com/"
        ? "pk_live_PL3BEeH12WSDR5RDswd9C2kx00ifdZgJ77"
        : "pk_test_FxWnxNsFVLXVBiHuPeajaF2O005Xz60Iyz";
  }

  componentDidMount() {
    this.setState({
      email: this.props.userDetails.email,
      mobileNumber: this.props.userDetails.mobileNumber,
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.reservation.)
  }

  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
      error: null,
    });
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
      error: null,
    });
  };

  onMobileNumberChange = (e) => {
    this.setState({
      mobileNumber: e.target.value,
      error: null,
    });
  };

  continue = () => {
    if (Utils.isBlank(this.state.mobileNumber)) {
      const errorMessage = "Please enter your phone number";
      this.setState({ error: errorMessage });

      ReactGA.event({
        category: "Reservation",
        action: "Continue failed",
        label: errorMessage,
      });
    } else if (!Utils.isValidEmail(this.state.email)) {
      const errorMessage = "Please enter a valid email address";
      this.setState({ error: errorMessage });

      ReactGA.event({
        category: "Reservation",
        action: "Continue failed",
        label: errorMessage,
      });
    } else {
      this.props.makeReservation(
        this.state.mobileNumber,
        this.state.email,
        this.props.reservation.arrival
      );
    }
  };

  render() {
    return (
      <ReserveBookingModal
        onNameChange={this.onNameChange}
        onMobileNumberChange={this.onMobileNumberChange}
        onEmailChange={this.onEmailChange}
        mobileNumber={this.state.mobileNumber}
        name={this.state.name}
        email={this.state.email}
        continue={this.continue}
        loading={this.props.reservation.makingReservation}
        error={
          this.state.error || this.props.reservation.makingReservationError
        }
        stripeApiKey={this.stripeApiKey}
        {...this.props}
      />
    );
  }

  componentWillUnmount() {
    if (this.props.reservation.paymentSuccessful) {
      this.props.resetReservation();
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userDetails: state.userDetails,
    reservation: state.reservation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    makeReservation: (mobileNumber, email, pickup) =>
      dispatch(makeReservation(mobileNumber, email, pickup)),
    resetReservation: () => dispatch(resetReservation()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReserveBookingModalContainer);
