import React from 'react';
import styles from './Header.styles';
import { Link } from 'gatsby';
import { css } from 'aphrodite';
import SecondaryButton from '../../../components/buttons/SecondaryButton/SecondaryButton';
import MenuIcon from '@material-ui/icons/Menu';
import { SERVICES } from '../../../library/services';
import COLORS from '../../../styles/colors';
import MobileMenuContainer from '../MobileMenu/MobileMenuContainer';
import STYLES from '../../../styles/shared';

const Header = (props) => (
  <header className={css(styles.root, props.overlay && styles.rootOverlay)}>
    <div className={css(styles.content)}>

      <ul className={css(styles.logoLinksUl)}>
        <li>
          <Link to={'/'}>
            <img alt={'Man and van app'} className={css(styles.headerIconLogo)} draggable={false}
              src={require('../../../assets/images/jjd-logo.png')} />
          </Link>
        </li>
      </ul>

      <ul className={css(styles.linksUl)}>
        <li className={css(styles.linkLi)} onMouseEnter={props.hoverServices} onMouseLeave={props.leaveServices}>
          <a className={css(styles.link, props.overlay && styles.linkOverlay)} onClick={props.clickServices} href={'#'}>Services</a>
          <div
            className={css(styles.servicesDropdown, props.servicesDropdownVisible && styles.servicesDropdownVisible)}>
            <span className={css(styles.arrowUp)} />
            {SERVICES.map((service, index) => (
              <Link
                key={service.name}
                className={css(styles.serviceOption, props.flashLinks[index] === true && styles.serviceOptionFlash)}
                onClick={() => props.clickService(service)} to={service.url}>
                {service.icon({ color: COLORS.white, fontSize: 32, marginRight: 24 })}
                {service.name}
              </Link>
            ))}
          </div>
        </li>
        <li className={css(styles.linkLi)}>
          <Link className={css(styles.link, props.overlay && styles.linkOverlay)} to={'/reviews'}>Reviews</Link>
        </li>
        {/*<li className={css(styles.linkLi)}>*/}
        {/*<Link className={css(styles.link, props.overlay && styles.linkOverlay)} to={'/cities'}>Cities</Link>*/}
        {/*</li>*/}
        {/*<li className={css(styles.linkLi)}>*/}
        {/*<Link className={css(styles.link, props.overlay && styles.linkOverlay)} to={'/pricing'}>Pricing</Link>*/}
        {/*</li>*/}
        <li className={css(styles.linkLi)}>
          <Link className={css(styles.link, props.overlay && styles.linkOverlay)} onClick={props.clickDrivers} to={'/drivers'}>Drivers</Link>
        </li>
        <li className={css(styles.linkLiButton)}>
          <SecondaryButton color={'white'} title={'Book online'} onClick={props.goToBooking} />
        </li>
        {/* {!props.isLoggedIn && <li className={css(styles.linkLi)}>
          <div className={css(styles.link, props.overlay && styles.linkOverlay)} onClick={props.login}>Login</div>
        </li>} */}
        {props.isLoggedIn && <li className={css(styles.linkLi)}>
          <div className={css(styles.link, !props.overlay && styles.welcomeBack, props.overlay && styles.linkOverlay, STYLES.noSelect)}>Welcome back, {props.firstName}</div>
        </li>}
      </ul>

      <div className={css(styles.menuIconContainer)}>
        <div className={css(styles.link, props.overlay && styles.linkOverlay)} onClick={props.openMobileMenu}>
          <MenuIcon className={css(styles.menuIcon)} />
        </div>
      </div>
    </div>


    {props.mobileMenuOpen && <MobileMenuContainer closeMobileMenu={props.closeMobileMenu} {...props} />}
  </header>
);

export default Header;
