import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import CreatePassword from './CreatePassword';
import { createPassword } from './CreatePasswordActions';
import { setCurrentModal } from '../Modal/ModalActions';
import { navigate } from 'gatsby';
import { resetBooking } from '../../bookingStep5/BookingSuccess/BookingSuccessActions';

class CreatePasswordModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showError: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.setEmailPasswordError && !this.props.auth.setEmailPasswordError) {
      this.setState({
        showError: true
      })
    }
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
      showError: false
    })
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submit()
    }
  };

  submit = () => {
    if (this.state.password.length >= 6) {
      this.props.createPassword(this.state.password);
    } else {
      this.setState({
        showError: true
      })
    }
  };

  done = () => {
    this.props.closeModal();
    this.props.resetBooking();
    navigate('/');
  };

  cancel = () => {
    this.props.closeModal();
    this.props.resetBooking();
    navigate('/');
  };

  render() {
    return (
      <CreatePassword
        password={this.state.password}
        onPasswordChange={this.onPasswordChange}
        showError={this.state.showError}
        submit={this.submit}
        cancel={this.cancel}
        done={this.done}
        onKeyPress={this.onKeyPress}

        {...this.props}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userDetails: state.userDetails
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createPassword: (password) => dispatch(createPassword(password)),
    closeModal: () => dispatch(setCurrentModal(null)),
    resetBooking: () => dispatch(resetBooking())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordModalContainer);
