import React from 'react';
import styles from './StripeMakePayment.styles';
import { css } from 'aphrodite';
import Button from '@material-ui/core/Button/Button';
import { CardElement } from 'react-stripe-elements';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

// stripe-card-element class styles in index.css

const StripeMakePayment = (props) => (
  <div className={css(styles.addCardContainer)}>
    <CardElement className={'stripe-card-element'} />
    <Button variant={'contained'} color={'primary'} className={css(styles.addCardButton)}
            onClick={props.submit}
            disabled={(props.reservation.paymentInProgress || props.waitingForToken)}>
      {(!props.reservation.paymentInProgress && !props.waitingForToken) && 'Confirm reservation'}
      {(props.reservation.paymentInProgress || props.waitingForToken) && <CircularProgress color={'inherit'} size={16} thickness={6}/>}
    </Button>
  </div>
);

export default StripeMakePayment;
