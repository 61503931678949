import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = (width) => StyleSheet.create({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.48)',
    zIndex: 999, // not going over Chat widget ...

    // Animation stuff:
    opacity: 0,
    animationName: {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    animationDuration: '100ms',
    animationIterationCount: 1,
    animationFillMode: 'forwards'
  },
  modalContent: {
    // maxWidth: 800,
    background: COLORS.white,
    borderRadius: 4,
    maxHeight: 'calc(100vh - 16px)', // scrollable
    overflow: 'hidden',
    // boxShadow: '0 8px 40px rgba(0,0,0,0.08)', - can't with overflow: hidden ...
    position: 'absolute',
    left: '50%',
    top: 200,
    width: '100%',
    maxWidth: width,
    marginLeft: -(width/2),
    ['@media only screen and (max-width: ' +  width + 'px)']: {
      marginLeft: 0,
      left: 8,
      top: 8,
      width: 'calc(100% - 16px)'
    },


    // Animation Stuff:
    transform: 'translate3d(0,20px,0)',
    // transform: 'translateY()',
    animationName: {
      '0%': {
        transform: 'translate3d(0,20px,0)',
      },
      '100%': {
        transform: 'translate3d(0,0,0)',
      }
    },
    animationDuration: '250ms',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    [QUERIES.mobile]: {
      overflowY: 'scroll',
      paddingBottom: 124
    },
    paddingBottom: 32
  }
});

export default styles;
