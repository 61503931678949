const QUERIES = {
  desktop: '@media only screen and (min-width: 900px)',
  mobile: '@media only screen and (max-width: 900px)',
  greaterThan520: '@media only screen and (min-width: 520px)',

  // 200px increments
  lessThan400: '@media only screen and (max-width: 400px)',
  lessThan500: '@media only screen and (max-width: 500px)',
  lessThan470: '@media only screen and (max-width: 470px)',
  lessThan600: '@media only screen and (max-width: 600px)',
  lessThan800: '@media only screen and (max-width: 800px)',
  lessThan1000: '@media only screen and (max-width: 1000px)',
  lessThan1060: '@media only screen and (max-width: 1060px)',
  lessThan1200: '@media only screen and (max-width: 1200px)',
  lessThan1400: '@media only screen and (max-width: 1400px)',
  lessThan1600: '@media only screen and (max-width: 1600px)'
};

export default QUERIES;
