import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    // ...
  },
  paymentContainer: {
    [QUERIES.mobile]: {
      padding: 16
    }
  },
  content: {
    display: 'flex'
  },
  headerArea: {
    padding: 24,
  },
  header: {
    margin: 0
  },
  description: {
    fontSize: 16,
    margin: 0,
    paddingTop: 8
  },
  input: {
    flex: 1,
    padding: 24,
    fontSize: 22,
    display: 'flex',
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    color: COLORS.offBlack
  },
  firstInput: {
    borderTop: '1px solid whitesmoke'
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24
  },
  forgotPasswordButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.circularBold,
    textTransform: 'none',
    color: '#757575',
    marginRight: 16
  },
  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 14,
    paddingLeft: 24,
    paddingTop: 12,
    lineHeight: '39px',
    margin: 0
  },


  // Styles from payment: ...
  titleArea: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
    position: 'relative'
  },
  paymentArea: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    flexWrap: 'wrap',
    minHeight: 360
  },
  paymentSummaryContainer: {
    flex: 1,
    minWidth: 300
  },
  paymentSummaryContent: {
    padding: 32,
    paddingTop: 0,
    [QUERIES.mobile]: {
      padding: 16
    }
  },
  cardContainer: {
    flex: 1,
    padding: 32,
    minWidth: 300,
    [QUERIES.mobile]: {
      padding: 0
    }
  },
  cardContainerContent: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    background: COLORS.white,
    margin: '0 auto',
    borderRadius: 4,
  },
  paymentMethodTitle: {
    fontSize: 16,
    margin: 0,
    lineHeight: 'normal',
    paddingTop: 6
  },
  priceRow: {
    color: COLORS.lightBlack,
    paddingBottom: 4
  },
  cardRow: {
    color: '#bbbbbb'
  },
  confirmationText: {
    fontSize: 14,
    margin: 0,
    paddingBottom: 8,
    paddingTop: 16,
    color: COLORS.offBlack
  },
  confirmationTextTwo: {
    fontSize: 14,
    margin: 0,
    paddingBottom: 16,
    paddingTop: 8,
    // fontWeight: 'bold'
  },
  priceInfoText: {
    fontSize: 12,
    margin: 0
  },
  priceBreakdownContainer: {
    paddingBottom: 12
  },
  acceptedCardsContainer: {
    padding: 4,
    border: '1px solid whitesmoke',
    borderRadius: 4,
    [QUERIES.mobile]: {
      border: 'none'
    }
  },
  secureLabel: {
    position: 'absolute',
    color: '#c1c1c1',
    fontSize: 10,
    textAlign: 'center',
    bottom: 6,
    right: 6,
    background: 'white',
    paddingLeft: 6,
    paddingRight: 6,
    [QUERIES.mobile]: {
      display: 'none'
    }
  },
  cardImg: {
    width: 32,
    marginRight: 4,
    [QUERIES.mobile]: {
      width: 18
    }
  },
  confirmButton: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    fontWeight: 'bold',
    marginTop: 4,
    height: 48
  },
  errorText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.hotPink
  },
  paymentFailedContainer: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row'
  },
  retryActionText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.kgDarkGreen
  },
  orText: {
    color: '#bbbbbb',
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4
  },
  changeCardActionText: {
    margin: 0,
    fontSize: 12,
    color: COLORS.offBlack
  },
  paymentSuccessContainer: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  doneIcon: {
    color: COLORS.kgDarkGreen,
    height: 64,
    width: 64,
    [QUERIES.mobile]: {
      textAlign: 'center',
      paddingBottom: 24,
      margin: '0 auto'
    }
  },
  doneText: {
    color: COLORS.kgDarkGreen,
    fontSize: 14,
    paddingTop: 16,
    paddingLeft: 24,
    margin: 0,
    flex: 1,
    [QUERIES.mobile]: {
      padding: 0,
      flex: '0 0 100%'
    }
  }
});

export default styles;
