import { StyleSheet } from 'aphrodite';
import { isMobile } from 'react-device-detect';
import COLORS from './colors';

const STYLES = StyleSheet.create({
  desktopOnly: {
    display: isMobile ? 'none' : 'inherit'
  },
  mobileOnly: {
    display: isMobile ? 'inherit' : 'none'
  },
  noSelect: {
    'user-select': 'none'
  },
  numberAndPhone: {
    flexDirection: 'row',
    padding: '48px 0',
    display: 'flex'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  dontShow: {
    display: 'none'
  },
  documentContent: {
    padding: '32px 16px',
    maxWidth: 900,
    margin: '0 auto',
    background: COLORS.white
  },
  cardBoxShadow: {
    boxShadow: 'none'
  },
  boxShadow: '0 5px 40px 0 rgba(0, 0, 0, 0.11)'
});

export default STYLES;
