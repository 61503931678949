import React, { Component } from 'react';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Header from './Header';
import { connect } from 'react-redux';
import { setCurrentModal } from '../../modals/Modal/ModalActions';
import { LOGIN_MODAL } from '../../../library/modals';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredLink: null,
      servicesDropdownVisible: false,
      mobileMenuOpen: false,
      flashLinks: {
        0: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false
      }
    }
  }

  openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: true
    })
  };

  closeMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false
    })
  };

  clickDownload = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Download clicked - HeaderContainer',
      label: 'HeaderContainer'
    });
    ReactPixel.track('Lead', {
      content_name: 'HeaderContainer',
      content_category: 'Download Clicked'
    });
    navigate('/man-and-van-app');
  };

  clickDrivers = () => {
    navigate('/drivers');
  };

  goToBooking = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Book online clicked - HeaderContainer',
      label: 'HeaderContainer'
    });
    navigate('/book');
  };

  hoverServices = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Services dropdown hovered',
      label: 'HeaderContainer'
    });
    this.setState({
      servicesDropdownVisible: true
    })
  };

  leaveServices = () => {
    this.setState({
      servicesDropdownVisible: false
    })
  };

  clickService = (service) => {
    ReactGA.event({
      category: 'Browsing',
      action: service + ' clicked from Header',
      label: 'Header service click'
    });
  };

  clickServices = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Services clicked',
      label: 'Produces fun, flashing effect'
    });
    this.setState({
      servicesDropdownVisible: true
    }, () => {
      for (let i = 0; i < 7; i++) {
        setTimeout(() => {
          this.setState({flashLinks: {...this.state.flashLinks, [i]: true}})
        }, i*68)
      }
      setTimeout(() => {
        this.setState({flashLinks: {0: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false}})
      }, 800)
    });
  };

  render() {
    return (
      <Header {...this.props}
              leaveServices={this.leaveServices}
              hoverServices={this.hoverServices}
              clickDownload={this.clickDownload}
              clickDrivers={this.clickDrivers}
              goToBooking={this.goToBooking}
              servicesDropdownVisible={this.state.servicesDropdownVisible}
              mobileMenuOpen={this.state.mobileMenuOpen}
              openMobileMenu={this.openMobileMenu}
              closeMobileMenu={this.closeMobileMenu}
              clickService={this.clickService}
              login={this.props.login}
              clickServices={this.clickServices}
              flashLinks={this.state.flashLinks}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    firstName: state.userDetails.firstName
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: () => dispatch(setCurrentModal(LOGIN_MODAL))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
