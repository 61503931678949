/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import HeaderContainer from "../layout/Header/HeaderContainer";
import Footer from "../layout/Footer/Footer";
import AnalyticsContainer from "../bootstrap/Analytics/AnalyticsContainer";
import ModalContainer from "../modals/Modal/ModalContainer";
import LiveChatContainer from "../bootstrap/LiveChat/LiveChatContainer";
import ContactInfo from "../../components/ContactInfo/ContactInfoView";

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        {!props.hideHeader && (
          <HeaderContainer
            overlay={props.overlayHeader}
            greenHeader={props.greenHeader}
          />
        )}
        <main>{props.children}</main>
        {/* props.location && <AnalyticsContainer location={props.location} /> */}
        <ModalContainer location={props.location} />
        <ContactInfo />
        <LiveChatContainer location={props.location} />
        {!props.hideFooter && <Footer />}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
