import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    // ...
  },
  content: {
    display: 'flex'
  },
  headerArea: {
    padding: 24,
  },
  header: {
    margin: 0
  },
  input: {
    padding: 24,
    fontSize: 36,
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    color: COLORS.offBlack
  },
  firstInput: {
    borderTop: '1px solid whitesmoke'
  },
  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 14,
    lineHeight: '39px',
    margin: 0,
    padding: 12,
    paddingLeft: 16
  },
  checkingSpinner: {
    position: 'absolute',
    width: 28,
    height: 28,
    top: 30,
    right: 30
  },
  helperText: {
    flex: 1,
    fontSize: 16,
    margin: 0,
    position: 'relative',
    paddingTop: 8,
    paddingLeft: 24
  },
  helperTextArrow: {
    position: 'absolute',
    fontSize: 16,
    left: 0,
    top: 12
  },
});

export default styles;
