import React from "react";
import styles from "./FooterReviews.styles";
import { css } from "aphrodite";
import REVIEWS from "../../../library/reviews";
import QuoteMarks from "@material-ui/icons/FormatQuote";
import Star from "@material-ui/icons/StarRounded";
import COLORS from "../../../styles/colors";
import TrustPilot from "./TrustPilot";

const FooterReviews = () => (
  <div className={`${css(styles.root)} review-wrapper`}>
    <div className={css(styles.rootsub)}>
      <TrustPilot />
    </div>
  </div>
);

export default FooterReviews;
