import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    // ...
  },
  content: {
    display: 'flex'
  },
  headerArea: {
    padding: 24,
  },
  header: {
    margin: 0
  },
  input: {
    flex: 1,
    padding: 24,
    fontSize: 22,
    display: 'flex',
    border: 'none',
    borderBottom: '1px solid whitesmoke',
    color: COLORS.offBlack
  },
  firstInput: {
    borderTop: '1px solid whitesmoke'
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: 24
  },
  loginButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    // width: 80,
    height: 39
  },
  forgotPasswordButton: {
    boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.content,
    textTransform: 'none',
    color: '#757575',
    marginRight: 16
  },
  errorMessage: {
    color: COLORS.hotPink,
    fontSize: 14,
    paddingLeft: 24,
    paddingTop: 12,
    lineHeight: '39px',
    margin: 0
  }
});

export default styles;
