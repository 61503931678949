import React, { Component } from 'react';
import MobileMenu from './MobileMenu';
import ReactGA from 'react-ga';
import { navigate } from 'gatsby'

class MobileMenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {
    var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute('class', 'modal-open');
    // document.body.classList.add('modal-open');
    document.addEventListener('mousedown', this.handleClick, false);

    setTimeout(() => {
      this.setState({
        isOpen: true
      })
    }, 10)
  }

  close = () => {
    this.setState({
      isOpen: false
    }, () => {
      setTimeout(() => {
        this.props.closeMobileMenu();
      }, 340)
    })
  };

  trackDownloadClick = (platform) => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Download ' + platform + ' clicked in mobile menu',
      label: 'Mobile menu'
    });
    // todo: use OneLink
  };

  openLink = (to) => {
    ReactGA.event({
      category: 'Browsing',
      action: to + ' clicked in mobile menu',
      label: 'Mobile menu'
    });
    navigate(to);
    this.close();
  };

  render() {
    return (
      <MobileMenu
        isOpen={this.state.isOpen}
        openLink={this.openLink}
        close={this.close}
        {...this.props}
      />
    )
  }

  componentWillUnmount() {
    var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.removeAttribute('class', 'modal-open');
    document.removeEventListener('mousedown', this.handleClick, false);
  }
}

export default MobileMenuContainer;
