import React, {Component} from 'react';
import styles from './Modal.styles';
import { css } from 'aphrodite';

class Modal extends Component {
  componentDidMount() {
    var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute( 'class', 'modal-open' );

    // document.body.classList.add('modal-open');
    document.addEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      // The click is inside ... continue
      return;
    }

    this.props.closeModal();
  };

  render() {
    return (
      <div className={css(styles().root)}>
        <div className={css(styles(this.props.width).modalContent)} ref={node => this.node = node}>
          {this.props.children}
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
    root.removeAttribute( 'class', 'modal-open' );
    document.removeEventListener('mousedown', this.handleClick, false);
  }
}

export default Modal;
