import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  addCardContainer: {
    paddingTop: 12
  },
  addCardButton: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    fontWeight: 'bold',
    marginTop: 16,
    height: 48
  }
});

export default styles;
