import { StyleSheet } from 'aphrodite';
import { FONTS } from '../../../styles/fonts';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    // boxShadow: 'none',
    fontWeight: 'bold',
    fontFamily: FONTS.Buttons,
    textTransform: 'none',
    minWidth: 100,
    height: 48,
    borderRadius: 48,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 14
  },
  large: {
    fontSize: 16,
    height: 60,
    borderRadius: 60,
    paddingLeft: 42,
    paddingRight: 42
  },
  flat: {
    boxShadow: 'none'
  },
  aLinkWrapper: {
    '*': {
      textDecoration: 'none'
    }
  },
  white: {
    borderColor: 'transparent',//'rgba(0,0,0,0.16)',
    color: COLORS.white
  },
  yellow: {
    //backgroundColor: COLORS.brightYellow,
    //color: COLORS.black
  },
  pink: {
    //backgroundColor: COLORS.hotPink,
    // color: COLORS.white
  }
});

export default styles;
