import { css } from "aphrodite";
import styles from "./ContactInfo.styles";
import React from "react";

const ContactInfo = (props) => (
  <div>
    <div className={css(styles.contactContainerMobile)}>
      <p className={css(styles.contactText)}>Or prefer to call for a quote ?</p>
      <p className={css(styles.contactNum)}>
        <a className={css(styles.contactNum)} href="tel:02037452802">
          020 3745 2802
        </a>
      </p>
    </div>
  </div>
);

export default ContactInfo;
